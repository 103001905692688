<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <div class="mt-5">

      <Breadcrumbs :title="labels" :items="items"/>
      <Snackbar ref="snackbar"/>
      <Confirmation @action-delete="deleteQuestion()"/>

      <div class="text-center mt-7" v-if="$store.state.process.run == false && data.total == 0">
        <div class="mt-5">
          <v-img :src="require('@/assets/img/empty.png')" width="200" class="mx-auto"></v-img>
        </div>
        <div class="mt-3">Please add new Question</div>
        <div class="mt-2">by click the button below</div>
        <v-btn
          color="#F05326"
          depressed
          dense
          @click="createQuestion(data.total)"
          class="mt-3 white--text text-capitalize">
          Add New Question
        </v-btn>
      </div>

      <v-row justify="space-between" class="mt-2" v-if="$store.state.process.run == false && data.total > 0">
        <v-col cols="4">
          <div>
            <!-- <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Cari Topics . . ."
              v-model="searching"
              clearable
              color="#F05326"
              @click:clear="searching = '';"
              prepend-inner-icon="mdi-magnify">
            </v-text-field> -->
          </div>
        </v-col>
        <v-col cols="6" class="text-right">
          <div class="d-flex float-right">
            <!-- <v-btn 
              depressed
              dense
              @click="EditQuestions"
              elevation="0"
              color="#F5F5F5" 
              class="black--text text-capitalize mr-4">
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn> -->
            <v-btn
              color="#F05326"
              depressed
              dense
              @click="createQuestion(data.total)"
              class="white--text text-capitalize">
              Add New Question
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div v-if="$store.state.process.run" class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table">
        </v-skeleton-loader>
      </div>
      
      <div v-if="$store.state.process.run == false && data.total > 0" class="mt-3 mb-8">
        <v-data-table
          hide-default-footer
          fixed-header
          :loading="$store.state.process.run"
          :headers="headers"
          :items="data.list"
          :items-per-page.sync="data.limit"
          :page.sync="data.page"
          :server-items-length="data.total"
          item-key="id"
          :footer-props="{
            itemsPerPageText : 'Data per halaman',
            'items-per-page-options': [5,10, 20, 50, 100, 200]
          }">
          <template slot="footer" slot-scope="{ props }">
            <Pagination 
              @reload="fetch(page)"
              @changeLimit="limit = $event; fetch()" 
              @changePage="page = $event; fetch($event)" 
              :model="props"/>
          </template>

          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-wrap font-weight-bold" style="width: 450px">
                NUMBER {{ props.item.number }}
              </td>
              <td class="text-center">
                {{ props.item.created_at | datetime }}
              </td>
              <td clas="text-right">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ attrs ,on }">
                    <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="createQuestion(props.item)"
                      class="mr-2"
                      icon>
                      <v-icon>mdi-clipboard-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Questions</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ attrs ,on }">
                    <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      color="blue"
                      :to="`/questions/update/${$route.params.id_topic}/${$route.params.id_activity}/${props.item.id}/${props.item.number}`"
                      icon>
                      <!-- @click="EditQuestions(props.item, props.item.index)" -->
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Question</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ attrs ,on }">
                    <v-btn
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="confirmation(props.item.id)"
                      class="mr-2"
                      color="red"
                      icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Question</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
      
    </div>
  </div>
</template>

<script>
import GridTableGreeting from '@/components/course/activity/GridTableGreeting.vue'
import Pagination from '@/components/Pagination.vue'
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import Confirmation from '@/components/Confirmation.vue'
export default {
    components: { GridTableGreeting, Pagination, Breadcrumbs, Snackbar, Confirmation },
  data() {
    return {
      items: [
        {
          text: 'List Activity',
          disabled: false,
          href: `/activity/${this.$route.params.id_topic}`
        },
        {
          text: "",
          disabled: true  ,
          href: `/activity/${this.$route.params.id}`,
        },
      ],
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'Question', value: 'id', sortable: false },
        { text: 'Created at', value: 'created_at',align: "center", sortable: false },
        { text: '', value: 'action',align: "right", sortable: false }
      ],
      searching: "",
      labels: "",
      id_question: "",
      pagination: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      data: {},
      process: {
        run: false
      },
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
  },
  computed:{},
  created(){
    this.$store.state.process.run = false
    this.fetch()
    this.fetchDetailActivity()
  },
  mounted(){},
  methods:{
    fetchDetailActivity(){
      get(`api/v1/activity/detail/${this.$route.params.id_topic}/${this.$route.params.id_activity}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.items[1].text = res.data.title
          this.labels = res.data.title
        }
      })
    },
    async fetch(paging){
      this.$store.state.process.run = true;
      await get(`api/v1/question/list/${this.$route.params.id_topic}/${this.$route.params.id_activity}`, {
        params: {
          limit: this.$store.state.pagination.limit,
          page: this.$store.state.pagination.page,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.process.run = false;
          this.data = res.data;

          var data_list = [];
          var number = (this.$store.state.pagination.page - 1) * this.$store.state.pagination.limit + 1;
          res.data.list.forEach((item,i) => {
            // if (paging > 1) {
            //   item.index = parseInt(i) + 10
            // }else {
            //   item.index = parseInt(i)
            // }
            var data_temp = item;
            data_temp.number = number;
            get(`bank/v1/question/detail/${item.id}`)
            .then((response) => {
              let res = response.data
              if(res.status == 200){
                this.$store.state.process.run = false
                item.detail = res.data
              }
            })

            data_list.push(data_temp);
            number++;
          })

          this.data.list = data_list
        }else{
          this.$store.state.process.run = false;
        }
      });
    },
    EditQuestions(item, number){
      // this.$router.push(`/questions/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}`)
      
      // this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
      // switch (this.$route.params.activity_type) {
      //   case "compose_words":
      //     this.$router.push(`/questions/constructorword/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "reading_fill_the_blank":
      //     this.$router.push(`/questions/filltheblank/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "listening_audio":
      //     this.$router.push(`/questions/listening/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "vocabulary_matching":
      //     this.$router.push(`/questions/matching/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "reading_paragraph":
      //     this.$router.push(`/questions/reading/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "recording_sentences":
      //     this.$router.push(`/questions/sentencerecord/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "vocabulary_image":
      //     this.$router.push(`/questions/vocabulary/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      //   case "recording_audio":
      //     this.$router.push(`/questions/voices/update/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${item.id}/${this.$route.params.activity_type}`)
      //     break;
      // }
    },
    createQuestion(number){
      this.$router.push(`/questions/create/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${number + 1}`)


      // switch (this.$route.params.activity_type) {
      //   case "compose_words":
      //     this.$router.push(`/questions/constructorword/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "reading_fill_the_blank":
      //     this.$router.push(`/questions/filltheblank/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "listening_audio":
      //     this.$router.push(`/questions/listening/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "vocabulary_matching":
      //     this.$router.push(`/questions/matching/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "reading_paragraph":
      //     this.$router.push(`/questions/reading/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "recording_sentences":
      //     this.$router.push(`/questions/sentencerecord/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "vocabulary_image":
      //     this.$router.push(`/questions/vocabulary/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      //   case "recording_audio":
      //     this.$router.push(`/questions/voices/create/${this.$route.params.id_course}/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.$route.params.activity_type}`)
      //     break;
      // }
    },
    confirmation(id){
      this.$store.state.dialog.confirmDelete = true
      this.id_question = id
    },
    async deleteQuestion(){
      this.$store.state.process.run = true
      await destroy(`api/v1/question/delete/${this.$route.params.id_topic}/${this.$route.params.id_activity}/${this.id_question}`)
      .then(response => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.fetch()
          this.fetchDetailActivity()
          this.$refs.snackbar.open("#4CAF50", `Question Deleted Succesfully`);
        }else{
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.fetch()
          this.fetchDetailActivity()
          this.$refs.snackbar.open("error", `Question Deleted Failed`);
        }
      })
    },
  }
}
</script>